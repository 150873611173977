import { FormConfig } from 'src/app/shared/models/dynamic.model';

const commonProps = {
  type: 'input',
  required: false,
};

export const advancedSearchBagsFormFieldsConfig: FormConfig = {
  fields: [
    {
      ...commonProps,
      id: 'bag_ext',
      label: 'Bag',
    },
    {
      ...commonProps,
      id: 'origin',
      label: 'Origin',
    },
    {
      ...commonProps,
      id: 'destination',
      label: 'Destination',
    },
    {
      ...commonProps,
      id: 'receivingAgent',
      label: 'Receiving Agent',
    },
    {
      ...commonProps,
      id: 'FinalDestination',
      label: 'Final Destination',
    },
    {
      ...commonProps,
      id: 'clearanceType',
      label: 'Clearance Type',
    },
    {
      ...commonProps,
      id: 'customer',
      label: 'Customer',
    },
  ],
};

export interface BagsModal {
  bag_ext: string;
  origin: string;
  destination: string;
  receivingAgent: string;
  FinalDestination: string;
  clearanceType: string;
  customer: string;
}

export const defaultBagsModal: BagsModal = {
  bag_ext: '',
  origin: '',
  destination: '',
  receivingAgent: '',
  FinalDestination: '',
  clearanceType: '',
  customer: ''
};

export interface BagModalStateModel {
  detailModal: BagsModal;
}

// Initial state
export const defaultBagState: BagModalStateModel = {
  detailModal: defaultBagsModal,
};