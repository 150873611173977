import { FormConfig } from 'src/app/shared/models/dynamic.model';

export const statusCodeFormFieldsConfig: FormConfig = {
  fields: [
    {
      id: 'dateandTimeZone',
      type: 'input',
      label: 'Date, Time, Timezone',
      allowNumeric: true,
    },
    {
      id: 'codeandDecription',
      type: 'input',
      label: 'Code and Description',
      allowAlphaNumeric: true,
    },
    {
      id: 'information',
      type: 'input',
      label: 'Information',
      allowAlphaNumeric: true,
    },
  ],
};

export interface StatusModal {
  dateandTimeZone: string;
  codeandDecription: string;
  information: string;
}

export const defaultStatusModal: StatusModal = {
  dateandTimeZone: '',
  codeandDecription: '',
  information: '',
};

export interface StatusModalStateModel {
  statusModal: StatusModal;
}

// Initial state
export const defaultStatusState: StatusModalStateModel = {
  statusModal: defaultStatusModal,
};