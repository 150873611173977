import { Component } from '@angular/core';
import {  Router, NavigationEnd } from '@angular/router';
import { DefaultInfoHeaderIcons } from 'src/app/shared/utility/svg-constants';
import { menuItems } from 'src/app/shared/models/sidebar.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { SearchService } from '../../search/search.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  isExpanded: boolean = false;
  activeRoute: any | null = null;
  public hawbInfoHeaderIcons = DefaultInfoHeaderIcons;
  public menuItems = menuItems;
  pressedButtonIndex: number | null = null;

  constructor(
    private readonly router: Router,
    private readonly commonService: CommonService,
    private readonly searchService: SearchService
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.activeRoute = this.extractRouteSegment(this.router.url);
        const menuItemIndex = this.menuItems.findIndex(menuItem => {
          return menuItem.routerLink.indexOf(this.activeRoute) !== -1;
        });
        if (menuItemIndex !== -1) {
          this.onButtonClick(menuItemIndex);
        }
      }
    });
  }

  public toggleSidenav() {
    this.isExpanded = !this.isExpanded;
    setTimeout(() => {
      this.commonService.getMenuExpanded(this.isExpanded);
    }, 100);
  }

  public onButtonClick(index: number) {
    this.pressedButtonIndex = index;
    this.searchService.setClickedHyperlinkData({});
  }

  private clearPressedButton() {
    this.pressedButtonIndex = null;
  }

  private extractRouteSegment(url: string): string {
    const segments = url.split('/');
    return segments[1] || '';
  }
}
