import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  FormConfig,
  IDropdownResponse,
  ILookupDropdownResponse,
} from 'src/app/shared/models/dynamic.model';
import { ValidatorService } from 'src/app/shared/services/form-control-validators.service';
import { criteriaFormfieldsConfig } from '../../bag.config';
import { Observable, Subject, map, startWith, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { SaveBagCriteria } from '../../store/bag.action';
import { IBagCriteriaData } from 'src/app/features/model/bag.model';
import {
  ActionIcons,
  MasterLookup,
  SvgIconFrom,
  validationErrorMessages,
} from 'src/app/shared/utility/constants';
import { MessagePopupComponent } from 'src/app/shared/components/message-popup/message-popup.component';
import { ModalService } from 'src/app/shared/services/modal.service';

@Component({
  selector: 'app-criterias',
  templateUrl: './criterias.component.html',
  styleUrl: './criterias.component.scss',
})
export class CriteriasComponent implements OnInit, OnDestroy {
  @ViewChild('minHawbValueInput') minHawbValueInput!: ElementRef;
  @ViewChild('maxHawbValueInput') maxHawbValueInput!: ElementRef;
  @ViewChild('minHawbWeightInput') minHawbWeightInput!: ElementRef;
  @ViewChild('maxHawbWeightInput') maxHawbWeightInput!: ElementRef;

  public bagCriteriaData$!: Observable<IBagCriteriaData>;
  public criteriaForm!: FormGroup;
  public criteriaFieldsJsonConfig!: FormConfig;
  public clearanceTypeData!: IDropdownResponse[];
  public filteredClearanceTypeOptions!:
    | Observable<IDropdownResponse[]>
    | undefined;
  public agentTypeData!: IDropdownResponse[];
  public filteredAgentOptions!: Observable<IDropdownResponse[]> | undefined;
  public airlineCodeData!: any;
  public filteredAirlineCodeOptions!:
    | Observable<IDropdownResponse[]>
    | undefined;
  public serviceTypeData!: IDropdownResponse[];
  public filteredServiceTypeOptions!:
    | Observable<IDropdownResponse[]>
    | undefined;
  public customerIdData!: any;
  public filteredCustomerIdOptions!:
    | Observable<IDropdownResponse[]>
    | undefined;
  public customerGroupNameData!: IDropdownResponse[];
  public filteredCustomerGroupNameOptions!:
    | Observable<IDropdownResponse[]>
    | undefined;
  public finalFacilityData!: IDropdownResponse[];
  public finalDestinationData!: IDropdownResponse[];
  public filteredFinalFacilityOptions!:
    | Observable<IDropdownResponse[]>
    | undefined;
  public filteredDestinationOptions!:
    | Observable<IDropdownResponse[]>
    | undefined;
  public maxHawbWeightError = false;
  public minHawbWeightError = false;
  public maxHawbValueError = false;
  public minHawbValueError = false;
  public pageMode!: string;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  public setBagCriteriaWidth: any = {};
  public setBagCriteriaErrorTooltip: any = {};
  public setBagCriteriaTooltipEvent: any = {};
  public actionIconsList = ActionIcons;
  public svgIconFromList = SvgIconFrom;
  public validationErrorMessages = validationErrorMessages;
  private optionClearanceSelected = false;

  constructor(
    private readonly formUtilsService: ValidatorService,
    public commonService: CommonService,
    private readonly route: ActivatedRoute,
    private readonly store: Store,
    private readonly modalService: ModalService
  ) {
    this.loadInitialApi();
    this.pageMode = 'Add';
    this.bagCriteriaData$ = this.store.select(
      state => state.bagCriteria.criteria
    );
  }

  ngOnInit(): void {
    this.criteriaFieldsJsonConfig = criteriaFormfieldsConfig;
    this.createForm();
    this.route.queryParamMap.subscribe(params => {
      if (params.get('mode') === 'bulk-edit') {
        this.pageMode = 'Bulk-Edit';
      } else if (params.get('mode') === 'edit') {
        this.pageMode = 'Edit';
      } else if (
        params.get('mode') === 'view' ||
        params.get('mode') === 'bulk-view'
      ) {
        this.pageMode = 'View';
      }
    });
    this.getPageMode();
    this.checkForBagCriteriaPageSidebarExpanded();
    this.criteriaForm
      .get(MasterLookup.clearanceTypeVal)
      ?.valueChanges.subscribe(value => {
        this.optionClearanceSelected = false;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public createForm() {
    const { form } = this.formUtilsService.generateForm(
      this.criteriaFieldsJsonConfig
    );
    this.criteriaForm = form;
  }

  public checkMinMaxHawbValues(controlName: string) {
    const min = +this.criteriaForm.get('minHawbValue')?.value;
    const max = +this.criteriaForm.get('maxHawbValue')?.value;
    const isBothValid =
      this.commonService.isValidField(min) &&
      this.commonService.isValidField(max) &&
      min !== 0 &&
      max !== 0;
    if (isBothValid && min > max) {
      this.commonService.isCriteriaData(true);
      if (controlName === 'maxHawbValue') {
        this.setError('maxHawbValue');
        this.maxHawbValueError = true;
        this.minHawbValueError = false;
      } else {
        this.setError('minHawbValue');
        this.maxHawbValueError = false;
        this.minHawbValueError = true;
      }
    } else {
      this.ComparisonClearError('minHawbValue');
      this.ComparisonClearError('maxHawbValue');
      this.commonService.isCriteriaData(false);
      this.maxHawbValueError = false;
      this.minHawbValueError = false;
    }
  }

  public checkMinMaxHawbWeights(controlName: string) {
    const min = +this.criteriaForm.get('minBagWeight')?.value;
    const max = +this.criteriaForm.get('maxBagWeight')?.value;
    const isBothValid =
      this.commonService.isValidField(min) &&
      this.commonService.isValidField(max) &&
      min !== 0 &&
      max !== 0;
    if (isBothValid && min > max) {
      this.commonService.isCriteriaData(true);
      if (controlName === 'maxBagWeight') {
        this.setError('maxBagWeight');
        this.maxHawbWeightError = true;
        this.minHawbWeightError = false;
      } else {
        this.setError('minBagWeight');
        this.maxHawbWeightError = false;
        this.minHawbWeightError = true;
      }
    } else {
      this.ComparisonClearError('maxBagWeight');
      this.ComparisonClearError('minBagWeight');
      this.commonService.isCriteriaData(false);
      this.maxHawbWeightError = false;
      this.minHawbWeightError = false;
    }
  }

  public criteriaControlError(controlName: string) {
    return (
      this.criteriaForm.get(controlName)?.touched &&
      this.criteriaForm.get(controlName)?.errors
    );
  }

  public dispatchCriteriaFormValueChanges() {
    this.updateFinalFacilityId();
    this.updateAirlineId();
    this.updateFormValues();
    this.saveBagCriteria();
    this.checkPristineAccordionData();
    this.checkValidAccordionData();
    this.updateCustomer();
  }

  public formAutocompleteValueChanges(
    controlName: string,
    apiControlName: string,
    dropdownResponse: any
  ) {
    return this.commonService.getAutocompleteDropdownId(
      dropdownResponse,
      this.criteriaForm,
      controlName,
      apiControlName
    );
  }

  public clearErrors(event: any, controlName: string) {
    if (!event.target.value) {
      const controlErrors = this.criteriaForm.get(controlName)?.errors;
      if (controlErrors && Object.keys(controlErrors).length > 0) {
        this.criteriaForm.get(controlName)?.setErrors(null);
      }
    }
  }

  public isAutocompleteError(controlName: string): string {
    const control = this.criteriaForm.get(controlName);
    if (control?.value && control.errors?.['autocompleteError']) {
      this.checkValidAccordionData();
      return validationErrorMessages.autocompleteError;
    } else {
      return '';
    }
  }

  public fetchWidth(event: MouseEvent, fieldName: string) {
    this.commonService.setErrorTooltipData(
      event,
      fieldName,
      this.setBagCriteriaWidth,
      this.setBagCriteriaTooltipEvent,
      this.setBagCriteriaErrorTooltip
    );
  }

  public checkControlHasError(controlName: string): boolean {
    const control = this.criteriaForm.get(controlName);
    return control?.value ? control.hasError('autocompleteError') : false;
  }

  public onClearanceFocusOut() {
    this.commonService.getAutocompleteDropdownId(
      this.clearanceTypeData,
      this.criteriaForm,
      MasterLookup.clearanceTypeVal,
      MasterLookup.clearanceType
    );
    // Delay the popup validation to allow the selection to complete
    setTimeout(() => {
      if (!this.optionClearanceSelected) {
        this.validationPackagingPopup();
      }
      this.dispatchCriteriaFormValueChanges();
    }, 200);
  }

  public onClearanceOptionSelected() {
    this.optionClearanceSelected = true;
    this.dispatchCriteriaFormValueChanges();
  }

  private validationPackagingPopup() {
    this.commonService.validateAndShowPopup(
      this.criteriaForm,
      MasterLookup.clearanceTypeVal,
      this.modalService,
      MessagePopupComponent,
      validationErrorMessages.clearanceTypeError
    );
  }

  private updateFinalFacilityId() {
    if (
      typeof this.criteriaForm.value.finalFacility === 'string' &&
      this.finalFacilityData
    ) {
      const matchingFacility = this.finalFacilityData.find(
        (a: any) => a.name === this.criteriaForm.value.finalFacility
      );
      if (matchingFacility) {
        this.criteriaForm
          .get('hawbFinalFacility')
          ?.setValue(matchingFacility.id);
      } else {
        this.criteriaForm.get('hawbFinalFacility')?.setValue('');
        this.criteriaForm
          .get('finalFacility')
          ?.setValue(this.criteriaForm.value.finalFacility);
      }
    }
    if (
      typeof this.criteriaForm.value.finalDestinationId === 'string' &&
      this.finalDestinationData
    ) {
      const matchingDestination = this.finalDestinationData.find(
        (a: any) => a.name === this.criteriaForm.value.finalDestinationId
      );
      if (matchingDestination) {
        this.criteriaForm
          .get('finalDestination')
          ?.setValue(matchingDestination.id);
      } else {
        this.criteriaForm.get('finalDestination')?.setValue('');
        this.criteriaForm
          .get('finalDestinationId')
          ?.setValue(this.criteriaForm.value.finalDestinationId);
      }
    }
  }

  private updateCustomer() {
    if (
      typeof this.criteriaForm.value.custId === 'string' &&
      this.customerIdData
    ) {
      const matchingCustomer = this.customerIdData.find(
        (a: any) => a.name === this.criteriaForm.value.custId
      );
      if (matchingCustomer) {
        this.criteriaForm.get('customer')?.setValue(matchingCustomer.id);
      }
    }
    if (
      typeof this.criteriaForm.value.serviceTypeGroupsVal === 'string' &&
      this.serviceTypeData
    ) {
      const matchingService = this.serviceTypeData.find(
        (a: any) => a.name === this.criteriaForm.value.serviceTypeGroupsVal
      );
      if (matchingService) {
        this.criteriaForm
          .get('serviceTypeGroups')
          ?.setValue(matchingService.id);
      }
    }
  }

  private updateAirlineId() {
    if (
      typeof this.criteriaForm.value.airline === 'string' &&
      this.airlineCodeData
    ) {
      const matchingAirline = this.airlineCodeData.find(
        (a: any) => a.name === this.criteriaForm.value.airline
      );
      if (matchingAirline) {
        this.criteriaForm.get('airlineId')?.setValue(matchingAirline.id);
      }
    }
  }

  private updateFormValues() {
    this.criteriaForm.patchValue({
      agentTypeData: this.agentTypeData,
      finalFacilityData: this.finalFacilityData,
      airlineData: this.airlineCodeData,
      customerIdData: this.customerIdData,
      serviceTypeData: this.serviceTypeData,
    });
  }

  private saveBagCriteria() {
    this.store.dispatch(new SaveBagCriteria(this.criteriaForm.value));
  }

  private checkPristineAccordionData() {
    this.commonService.checkPristineAccordionData(false);
  }

  private checkValidAccordionData() {
    this.commonService.checkValidAccordionData(this.criteriaForm.valid);
  }

  private async loadInitialApi() {
    const [
      agentTypeData,
      clearanceTypeData,
      airlineCodeData,
      serviceTypeData,
      customerIdData,
      customerGroupNameData,
      finalFacilityData,
      finalDestinationData,
    ]: any = await Promise.all([
      this.getMasterLookUps(MasterLookup.agentInfo),
      this.getMasterLookUps(MasterLookup.clearanceType),
      this.getMasterLookUps(MasterLookup.airlineCode),
      this.getMasterLookUps(MasterLookup.serviceTypeGroups),
      this.getMasterLookUps(MasterLookup.customerId),
      this.getMasterLookUps(MasterLookup.customerGroup),
      this.getMasterLookUps(MasterLookup.origin),
      this.getMasterLookUps(MasterLookup.destination),
    ]);
    this.agentTypeData = agentTypeData;
    this.filteredAgentOptions = this.autocompleteValueChanges(
      'agent',
      this.agentTypeData
    );
    this.clearanceTypeData = clearanceTypeData;
    this.clearanceTypeData.forEach((clearance: any) => {
      clearance.name = `${clearance.id} - ${clearance.name}`;
    });
    this.filteredClearanceTypeOptions = this.autocompleteValueChanges(
      MasterLookup.clearanceTypeVal,
      this.clearanceTypeData
    );
    this.airlineCodeData = airlineCodeData;
    this.airlineCodeData.forEach((customer: any) => {
      customer.name = `${customer.code} - ${customer.name}`;
    });
    this.filteredAirlineCodeOptions = this.autocompleteValueChanges(
      'airline',
      this.airlineCodeData
    );
    this.serviceTypeData = serviceTypeData;
    this.serviceTypeData.forEach((service: any) => {
      service.name = `${service.id} - ${service.name}`;
    });
    this.filteredServiceTypeOptions = this.autocompleteValueChanges(
      'serviceTypeGroupsVal',
      this.serviceTypeData
    );
    this.customerIdData = customerIdData;
    this.customerIdData.forEach((customer: any) => {
      customer.name = `${customer.accnt_id} - ${customer.name}`;
    });
    this.filteredCustomerIdOptions = this.autocompleteValueChanges(
      'custId',
      this.customerIdData
    );
    this.customerGroupNameData = customerGroupNameData;
    this.filteredCustomerGroupNameOptions = this.autocompleteIdValueChanges(
      'objectGroups',
      this.customerGroupNameData
    );
    this.finalFacilityData = finalFacilityData;
    this.filteredFinalFacilityOptions = this.autocompleteValueChanges(
      'finalFacility',
      this.finalFacilityData
    );
    this.finalDestinationData = finalDestinationData;
    this.filteredDestinationOptions = this.autocompleteValueChanges(
      'finalDestinationId',
      this.finalDestinationData
    );
    this.dataFetch();
  }

  private getMasterLookUps(requestType: string) {
    return new Promise((resolve, reject) => {
      try {
        this.commonService
          .getMasterLookUp(requestType)
          .subscribe((response: ILookupDropdownResponse) => {
            if (response && response.data) {
              resolve(response.data);
            } else {
              resolve([]);
            }
          });
      } catch (error) {
        reject([]);
      }
    });
  }

  /* Set filter event based on value changes */
  private autocompleteValueChanges(
    controlName: string,
    dropdownData: IDropdownResponse[]
  ) {
    return this.criteriaForm.get(controlName)?.valueChanges.pipe(
      startWith(''),
      map(val => {
        const filterValue = (val || '').trim().toLowerCase();
        if (!filterValue) {
          return dropdownData;
        }
        return dropdownData.filter(option =>
          option.name.toLowerCase().includes(filterValue)
        );
      })
    );
  }

  private dataFetch() {
    this.commonService.savedBagData
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data === 'dataBind') {
          this.fetchOldValues();
        }
      });
  }

  private fetchOldValues() {
    this.bagCriteriaData$.subscribe((data: any) => {
      this.updateAgent(data);
      this.updateFinalFacility(data);
      this.updateAirline(data);
      this.updateCustomerData(data);
      const clearanceTypeMap = this.clearanceTypeData.reduce(
        (map: any, obj: any) => {
          map[obj.id] = obj.name;
          return map;
        },
        {}
      );
      data.clearanceTypeVal = clearanceTypeMap[data.clearanceType] || '';
      this.criteriaForm.patchValue(data);
      if (this.agentTypeData) {
        this.initializeAutocompleteValues();
        this.initializeAutocompleteValuesId();
        this.initializeErrorMessages();
      }
    });
    this.store.dispatch(new SaveBagCriteria(this.criteriaForm.value));
  }

  private initializeAutocompleteValues() {
    const formFields = [
      { control: 'agent', data: this.agentTypeData },
      { control: 'finalDestinationId', data: this.finalDestinationData },
      { control: 'finalFacility', data: this.finalFacilityData },
      { control: 'airline', data: this.airlineCodeData },
      { control: 'custId', data: this.customerIdData },
      { control: 'serviceTypeGroupsVal', data: this.serviceTypeData },
      { control: MasterLookup.clearanceTypeVal, data: this.clearanceTypeData },
    ];
    formFields.forEach(field => {
      const control = this.criteriaForm.get(field.control);
      if (control?.value) {
        this.commonService.getAutocompleteDropdownBinding(
          field.data,
          this.criteriaForm,
          field.control,
          MasterLookup.noApiControl,
          'name'
        );
      }
    });
  }

  private initializeAutocompleteValuesId() {
    const formFields = [
      { control: 'objectGroups', data: this.customerGroupNameData },
    ];
    formFields.forEach(field => {
      const control = this.criteriaForm.get(field.control);
      if (control?.value) {
        this.commonService.getAutocompleteDropdownBinding(
          field.data,
          this.criteriaForm,
          field.control,
          MasterLookup.noApiControl,
          'id'
        );
      }
    });
  }

  private initializeErrorMessages() {
    const errorFields = [
      'agent',
      'finalDestinationId',
      'finalFacility',
      'airline',
      MasterLookup.clearanceTypeVal,
      'serviceTypeGroupsVal',
      'objectGroups',
      'custId',
    ];
    errorFields.forEach(field => {
      const control = this.criteriaForm.get(field);
      if (control?.value?.trim()) {
        const hasError = this.checkControlHasError(field);
        if (hasError) {
          this.isAutocompleteError(field);
        }
      }
    });
  }

  private updateAgent(data: any) {
    if (data.agentId && data.agentTypeData) {
      const matchingAgent = data.agentTypeData.find(
        (a: any) => a.id === data.agentId
      );
      data.agent = matchingAgent?.name;
    }
  }

  private updateFinalFacility(data: any) {
    if (typeof data.hawbFinalFacility !== 'string' && data.finalFacilityData) {
      const matchingFacility = data.finalFacilityData.find(
        (a: any) => a.id === data.hawbFinalFacility
      );
      if (matchingFacility) {
        data.finalFacility = matchingFacility.name;
      }
    }
    if (typeof data.finalDestination !== 'string' && data.finalFacilityData) {
      const matchingDestination = data.finalFacilityData.find(
        (a: any) => a.id === data.finalDestination
      );
      if (matchingDestination) {
        data.finalDestinationId = matchingDestination.name;
      }
    }
  }

  private updateAirline(data: any) {
    if (typeof data.airlineId !== 'string' && data.airlineCodeData) {
      const matchingAirline = data.airlineCodeData.find(
        (a: any) => a.id === data.airlineId
      );
      if (matchingAirline) {
        data.airline = matchingAirline.name;
      }
    }
  }

  private updateCustomerData(data: any) {
    if (typeof data.custId !== 'string' && data.customerIdData) {
      const matchingCust = data.customerIdData.find(
        (a: any) => a.id === data.customer
      );
      if (matchingCust) {
        data.custId = matchingCust.name;
      }
    }
    if (typeof data.serviceTypeGroups === 'string' && data.serviceTypeData) {
      const matchingService = data.serviceTypeData.find(
        (a: any) => a.id === data.serviceTypeGroups
      );
      if (matchingService) {
        data.serviceTypeGroupsVal = matchingService.name;
      }
    }
  }

  private getPageMode() {
    this.commonService.isBagPageMode
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: string) => {
        if (this.commonService.isValidField(data)) {
          this.pageMode = data;
          if (this.pageMode === 'View') {
            this.criteriaForm.disable();
          }
        }
      });
  }

  private autocompleteIdValueChanges(
    controlName: string,
    dropdownData: IDropdownResponse[]
  ) {
    return this.criteriaForm.get(controlName)?.valueChanges.pipe(
      startWith(''),
      map(val => {
        const searchTerm = val?.toLowerCase().trim();
        if (!searchTerm) {
          return dropdownData;
        }
        return dropdownData.filter(option =>
          option.id.toString().toLowerCase().includes(searchTerm)
        );
      })
    );
  }

  private ComparisonClearError(controlName: string) {
    const control = this.criteriaForm.get(controlName);
    if (control?.hasError('comparisonError')) {
      delete control.errors?.['comparisonError'];
      control.updateValueAndValidity();
    }
  }

  private setError(controlName: string) {
    this.criteriaForm.get(controlName)?.setErrors({ comparisonError: true });
  }

  private checkForBagCriteriaPageSidebarExpanded() {
    this.commonService.isMenuExpanded.subscribe(() => {
      this.setBagCriteriaWidth = this.commonService.setModifiedTooltipWidth(
        this.setBagCriteriaTooltipEvent,
        this.setBagCriteriaErrorTooltip,
        this.setBagCriteriaWidth
      );
    });
  }
}
