import { FormConfig } from "src/app/shared/models/dynamic.model";

export const commodityRoles: string[] = [
  'All',
  'Shipper',
  'Consignee',
  'Carrier',
  'Buyer',
  'Seller',
  'Final Reciepient',
  'Online Marketplace',
  'Other',
  'Broker',
  'Forwader',
  'Weight',
];

export interface RoleModal {
  role: string;
  country: string;
  name: string;
  company: string;
  address_1: string;
  isFromScreen: string;
  address_2: string;
  city: string;
  state: string;
  postalcode: string;
  phone: string;
  email: string;
}

export const defaultRoleModal: RoleModal = {
  role: '',
    country: '',
    name: '',
    company: '',
    address_1: '',
    isFromScreen: '',
    address_2: '',
    city:  '',
    state:  '',
    postalcode:  '',
    phone:  '',
    email:  '',
  };

export const roleAdvancedSearchFormFieldsConfig: FormConfig = {
  fields: [
    {
      id: 'role',
      type: 'input',
      label: 'Type',
      maxValue: 126,
    },
    {
      id: 'country',
      type: 'input',
      maxValue: 126,
      label: 'File Name',
    },
    {
      type: 'input',
      label: 'User Name',
      maxValue: 126,
      id: 'name',
    },
    { 
      label: 'Date',
      id: 'company',
      type: 'input',
      maxValue: 126,
    },
    {
      id: 'address_1',
      type: 'input',
      label: 'Description',
      maxValue: 126,
    },
    {
      id: 'address_2',
      label: 'Description',
      maxValue: 126,
      type: 'input',
    },
    {
      id: 'city',
      type: 'input',
      label: 'Description',
      maxValue: 126,
    },
    {
      id: 'state',
      type: 'input',
      maxValue: 126,
      label: 'Description',
    },
    {
      type: 'input',
      label: 'Description',
      maxValue: 126,
      id: 'postalcode',
    },
    {
      id: 'phone',
      label: 'Description',
      type: 'input',
      maxValue: 126,
    },
    {
      id: 'email',
      type: 'input',
      maxValue: 126,
      label: 'Description',
    },
    {
      id: 'isFromScreen',
      type: 'input',
      maxValue: 126,
      label: 'isFromScreen',
    },
  ],
};

export interface RoleModalStateModel {
  roleModals: RoleModal[];
}

export const defaultRoleState: RoleModalStateModel = {
  roleModals: [],
};