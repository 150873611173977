import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Subject, takeUntil } from 'rxjs';
import {
  ActionIcons,
  BulkDeleteItemNames,
  dateTimeFormat,
  gridMessages,
  InputRowKeys,
  MessageTemplates,
  pageType,
  selectedIdForSummaryTable,
} from 'src/app/shared/utility/constants';
import { CommonService } from 'src/app/shared/services/common.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { TableService } from '../tables.service';
import { TableColumnsProp } from 'src/app/shared/models/tables.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CheckboxStateService } from 'src/app/shared/services/checkbox-state.service';
import { IBasicSearchProps } from 'src/app/shared/models/dynamic.model';
import { MatPaginator } from '@angular/material/paginator';
import { CommonTableService } from 'src/app/shared/services/common-table.service';

@Component({
  selector: 'app-summary-table',
  templateUrl: './summary-table.component.html',
  styleUrl: './summary-table.component.scss',
})
export class SummaryTableComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @Input() summaryGridColumns!: TableColumnsProp[];
  @Input() tableData: any;
  @Input() isFrom!: string;
  @Input() dataSearched!: boolean;
  @Input() totalCount!: number;
  @Input() paginationProps!: IBasicSearchProps;
  @Output() pageChangeEvent = new EventEmitter<unknown>();
  @Output() tableSelectionEvent = new EventEmitter<string>();
  @Output() navigateData = new EventEmitter<any>();
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator
  ) {
    if (paginator) {
      this.actualPaginator = paginator;
      this.isInLastPage = this.actualPaginator.hasNextPage();
    }
  }

  public actualPaginator!: MatPaginator;
  public dataSource = new MatTableDataSource<any>([]);
  public selection = new SelectionModel<any>(true, []);
  public tableSortDirection!: string;
  public summaryCustomiseTableColumns!: TableColumnsProp[];
  public displayedColumns!: string[];
  public noSearchMessage = `${gridMessages.noSearch}`;
  public noMatchMessage!: string;
  public isAllCheckboxSelected!: boolean;
  public activeColumn!: string;
  public defaultSelectedColumn!: string;
  public selectedId!: string;
  public globalHeaderCheckboxState = false;
  public indeterminate = false;
  public isInLastPage = false;
  public previousPageSize = 10;
  public dateFormat = dateTimeFormat.onlyDate;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly globalSelectedIds: Set<number> = new Set<number>();
  private checkSelectedCount = 0;
  private isAllSelected = false;
  private selectAdditionalRows = false;

  constructor(
    public commonService: CommonService,
    public tableService: TableService,
    private readonly cdref: ChangeDetectorRef,
    private readonly checkboxService: CheckboxStateService,
    public commonTableService: CommonTableService
  ) {
    this.setRequiredInitialisation();
  }

  ngOnInit(): void {
    this.summaryCustomiseTableColumns = this.summaryGridColumns;
    this.getDefaultSearchMessage();
  }

  ngOnChanges() {
    this.dataSource.data = this.tableData;
    this.setDisplayColumnsForGrid();
    this.setGridColumnsOptions();
  }

  ngAfterViewInit() {
    this.getDataWithSortPagination();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  // For table header checkbox selection
  public onCheckAllChange(event: MatCheckboxChange): void {
    const isChecked = event.checked;
    this.isAllSelected = false;
    if (isChecked) {
      if (this.isFrom === pageType.mawb) {
        this.getSelectionDataById(selectedIdForSummaryTable.Mawb);
      } else if (this.isFrom === pageType.hawb) {
        this.getSelectionDataById(selectedIdForSummaryTable.Hawb);
      } else if (this.isFrom === pageType.manifest) {
        this.getSelectionDataById(selectedIdForSummaryTable.Manifest);
      } else if (this.isFrom === pageType.bag) {
        this.getSelectionDataById(selectedIdForSummaryTable.Bag);
      }
    } else {
      if (this.isFrom === pageType.mawb) {
        this.getDeSelectionDataById(selectedIdForSummaryTable.Mawb);
      } else if (this.isFrom === pageType.hawb) {
        this.getDeSelectionDataById(selectedIdForSummaryTable.Hawb);
      } else if (this.isFrom === pageType.manifest) {
        this.getDeSelectionDataById(selectedIdForSummaryTable.Manifest);
      } else if (this.isFrom === pageType.bag) {
        this.getDeSelectionDataById(selectedIdForSummaryTable.Bag);
      }
    }
    const pageLookup = {
      [pageType.account]: selectedIdForSummaryTable.Account,
      [pageType.agent]: selectedIdForSummaryTable.Agent,
    };
    if (isChecked) {
      this.getSelectionDataById(pageLookup[this.isFrom]);
    } else {
      this.getDeSelectionDataById(pageLookup[this.isFrom]);
    }
    this.updateHeaderCheckboxState();
    this.setIconHeader();
  }

  // for table body checkbox selection
  public checked(row: any) {
    const key = this.getCheckKey(row);
    if (!this.globalSelectedIds.has(key)) {
      this.globalSelectedIds.add(key);
      this.selection.select(row);
      this.checkSelectedCount++;
    }
    if (this.isAllSelected && this.globalSelectedIds.size === this.totalCount) {
      this.isAllSelected = true;
    } else {
      this.isAllSelected = false;
    }
    this.updateHeaderCheckboxState();
    this.setIconHeader();
  }

  public unchecked(row: any) {
    const key = this.getCheckKey(row);
    if (this.globalSelectedIds.has(key)) {
      this.globalSelectedIds.delete(key);
      this.selection.deselect(row);
      this.checkSelectedCount--;
    }
    this.isAllSelected = false;
    this.updateHeaderCheckboxState();
    this.setIconHeader();
  }

  public isChecked(row: any): boolean {
    const key = this.getCheckKey(row);
    return this.globalSelectedIds.has(key);
  }

  public dragAndDropGridColumns(event: CdkDragDrop<string[]>) {
    this.summaryCustomiseTableColumns =
      this.tableService.dragAndDropGridColumns(
        event,
        this.displayedColumns,
        this.summaryCustomiseTableColumns,
        this.tableService.getStorageName(this.isFrom),
        false
      );
  }

  // For customize column in table
  public handleCustomizeCheckboxSelection(index: number, isSelected: boolean) {
    const reorderedColumns = this.tableService.getFromLocalStorage(this.isFrom);
    this.tableService.handleTableCustomizeCheckboxSelection(
      index,
      isSelected,
      this.summaryCustomiseTableColumns,
      this.displayedColumns,
      reorderedColumns,
      false
    );
  }

  public onChangingPages(event: any) {
    const isPageSizeChanged = event.pageSize !== this.previousPageSize;
    this.previousPageSize = event.pageSize;
    if (isPageSizeChanged && this.globalHeaderCheckboxState) {
      this.selectAdditionalRows = true;
    } else {
      this.selectAdditionalRows = false;
    }
    this.paginationProps.paging.fromIndex =
      event.pageIndex * event?.pageSize + 1;
    this.paginationProps.paging.toIndex =
      this.paginationProps.paging.fromIndex + (event?.pageSize - 1);
    this.pageChangeEvent.emit({
      payload: this.paginationProps,
      isAllSelected: false,
    });
  }

  public getAllItemsSelectedMessage(): string {
    let allItemsMessage = '';
    if (this.isFrom === pageType.mawb) {
      allItemsMessage = MessageTemplates.AllItemsSelected(
        BulkDeleteItemNames.Mawbs,
        this.dataSource.data.length
      );
    } else if (this.isFrom === pageType.agent) {
      allItemsMessage = MessageTemplates.AllItemsSelected(
        BulkDeleteItemNames.Agents,
        this.dataSource.data.length
      );
    } else if (this.isFrom === pageType.hawb) {
      allItemsMessage = MessageTemplates.AllItemsSelected(
        BulkDeleteItemNames.Shipments,
        this.dataSource.data.length
      );
    } else if (this.isFrom === pageType.manifest) {
      allItemsMessage = MessageTemplates.AllItemsSelected(
        BulkDeleteItemNames.Manifests,
        this.dataSource.data.length
      );
    } else if (this.isFrom === pageType.bag) {
      allItemsMessage = MessageTemplates.AllItemsSelected(
        BulkDeleteItemNames.Bags,
        this.dataSource.data.length
      );
    }
    return allItemsMessage;
  }

  public getSelectAllItemsMessage(): string {
    let selectAllMessage = '';
    if (this.isFrom === pageType.mawb) {
      selectAllMessage = MessageTemplates.SelectAllItems(
        BulkDeleteItemNames.Mawbs,
        this.totalCount
      );
    } else if (this.isFrom === pageType.agent) {
      selectAllMessage = MessageTemplates.SelectAllItems(
        BulkDeleteItemNames.Agents,
        4 //providing static count for reference
      );
    } else if (this.isFrom === pageType.hawb) {
      selectAllMessage = MessageTemplates.SelectAllItems(
        BulkDeleteItemNames.Shipments,
        this.totalCount
      );
    } else if (this.isFrom === pageType.manifest) {
      selectAllMessage = MessageTemplates.SelectAllItems(
        BulkDeleteItemNames.Manifests,
        this.totalCount
      );
    } else if (this.isFrom === pageType.bag) {
      selectAllMessage = MessageTemplates.SelectAllItems(
        BulkDeleteItemNames.Bags,
        this.totalCount
      );
    }
    return selectAllMessage;
  }

  public selectAllCheckboxes() {
    this.isAllSelected = true;
    this.checkSelectedCount = this.totalCount;
    this.paginationProps.paging.fromIndex = 0;
    this.paginationProps.paging.toIndex = this.totalCount;
    this.pageChangeEvent.emit({
      payload: this.paginationProps,
      isAllSelected: true,
    });
  }

  public invokeMethodsAfterApiHit() {
    if (this.selectAdditionalRows) {
      this.selection.clear();
      this.checkAllCheckboxes(this.dataSource.data);
      this.selectAdditionalRows = false;
    }
    this.updateCheckboxStateForCurrentPage();
    this.updateHeaderCheckboxState();
    this.setIconHeader();
  }

  public invokeMethodsAfterAllSelected(dataFromParent: any) {
    this.checkAllCheckboxes(dataFromParent);
    this.updateHeaderCheckboxState();
  }

  public invokeMethodsAfterDeleteOrNewSearch() {
    this.selection.clear();
    this.checkSelectedCount = 0;
    this.globalSelectedIds.clear();
  }

  public updateHeaderCheckboxState() {
    const { globalHeaderCheckboxState, indeterminate } =
      this.checkboxService.updateHeaderCheckboxState(
        this.dataSource,
        this.isChecked.bind(this),
        this.isAllSelected
      );
    this.globalHeaderCheckboxState = globalHeaderCheckboxState;
    this.indeterminate = indeterminate;
  }

  public navigateToSpecificRecord(rowData: any) {
    this.selection.clear();
    this.navigateData.emit(rowData);
  }

  public consigneeTooltip(tooltipInfo: any): string {
    if (!tooltipInfo) return '';
    const name = tooltipInfo.name || '';
    const companyName = tooltipInfo.companyName || '';
    const address1 = tooltipInfo.address || '';
    const address2 = tooltipInfo.address_1 || '';
    const city = tooltipInfo.city || '';
    const state = tooltipInfo.state || '';
    const zipCode = tooltipInfo.zipCode || '';
    const country = tooltipInfo.country || '';
    const phone = tooltipInfo.phone || '';
    const email = tooltipInfo.email || '';

    let tooltipContent = '';
    tooltipContent += `${name}${companyName ? ', ' + companyName : ''}\n`;
    if (address1 || address2) {
      tooltipContent += `${address1}${address2 ? ', ' + address2 : ''}\n`;
    }
    tooltipContent += `${city}, ${state}, ${zipCode}, ${country}\n`;
    if (phone || email) {
      tooltipContent += `${phone}${phone && email ? ', ' : ''}${email}`;
    }
    return tooltipContent;
  }

  private getDataWithSortPagination() {
    this.sort.active = this.activeColumn;
    this.sort.direction = 'desc';
    this.dataSource.sort = this.sort;
    this.cdref.detectChanges();
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.tableSortDirection = data.direction;
    });
  }

  private setRequiredInitialisation() {
    this.tableSortDirection = 'desc';
    this.isAllCheckboxSelected = false;
  }

  private setGridColumnsOptions() {
    if (this.isFrom === pageType.mawb) {
      this.activeColumn = pageType.mawb;
      this.defaultSelectedColumn = InputRowKeys.Mawb;
      this.selectedId = selectedIdForSummaryTable.Mawb;
    } else if (this.isFrom === pageType.agent) {
      this.activeColumn = pageType.agent;
      this.defaultSelectedColumn = InputRowKeys.Agent;
      this.selectedId = selectedIdForSummaryTable.Agent;
    } else if (this.isFrom === pageType.hawb) {
      this.activeColumn = pageType.hawb;
      this.defaultSelectedColumn = InputRowKeys.hawbExt;
      this.selectedId = selectedIdForSummaryTable.Hawb;
    } else if (this.isFrom === pageType.manifest) {
      this.activeColumn = pageType.manifest;
      this.defaultSelectedColumn = InputRowKeys.ManifestNo;
      this.selectedId = selectedIdForSummaryTable.Manifest;
    } else if (this.isFrom === pageType.bag) {
      this.activeColumn = pageType.bag;
      this.defaultSelectedColumn = InputRowKeys.Bag;
      this.selectedId = selectedIdForSummaryTable.Bag;
    } else if (this.isFrom === pageType.account) {
      this.activeColumn = pageType.account;
      this.defaultSelectedColumn = InputRowKeys.Account;
      this.selectedId = selectedIdForSummaryTable.Account;
    }
  }

  private setDisplayColumnsForGrid() {
    this.displayedColumns = this.tableService.getFromLocalStorage(this.isFrom);
    this.tableService.setToLocalStorage(
      this.tableService.getStorageName(this.isFrom),
      JSON.stringify(this.displayedColumns)
    );
  }

  private getDefaultSearchMessage() {
    let moduleName = '';
    if (this.isFrom === pageType.mawb) {
      moduleName = BulkDeleteItemNames.Mawbs;
    } else if (this.isFrom === pageType.hawb) {
      moduleName = BulkDeleteItemNames.Shipments;
    } else if (this.isFrom === pageType.manifest) {
      moduleName = BulkDeleteItemNames.Manifests;
    } else if (this.isFrom === pageType.bag) {
      moduleName = BulkDeleteItemNames.Bags;
    }
    this.noSearchMessage = `${this.noSearchMessage} ${moduleName}`;
    this.noMatchMessage = gridMessages.noMawbMatch(moduleName);
  }

  private getSelectionDataById(key: string) {
    this.dataSource.data.forEach(row => {
      if (!this.globalSelectedIds.has(row[key])) {
        this.globalSelectedIds.add(row[key]);
        this.selection.select(row);
        this.checkSelectedCount++; // Increment for new selections
      }
    });
  }

  private getDeSelectionDataById(key: string) {
    this.dataSource.data.forEach(row => {
      if (this.globalSelectedIds.has(row[key])) {
        this.globalSelectedIds.delete(row[key]);
        this.selection.deselect(row);
        this.checkSelectedCount--;
      }
      this.selection.deselect(row);
    });
  }

  private getCheckKey(row: any) {
    let checkKey;
    if (this.isFrom === pageType.mawb) {
      checkKey = row.mawbId;
    } else if (this.isFrom === pageType.agent) {
      checkKey = row.agentId;
    } else if (this.isFrom === pageType.hawb) {
      checkKey = row.hawbId;
    } else if (this.isFrom === pageType.manifest) {
      checkKey = row.manifestId;
    } else if (this.isFrom === pageType.bag) {
      checkKey = row.bagId;
    } else if (this.isFrom === pageType.account) {
      checkKey = row.accountNumber;
    }
    return checkKey;
  }

  private checkAllCheckboxes(dataArray: any) {
    dataArray.forEach((row: any) => {
      const key = this.getCheckKey(row);
      if (!this.globalSelectedIds.has(key)) {
        this.globalSelectedIds.add(key);
        this.selection.select(row);
      }
    });
  }

  private updateCheckboxStateForCurrentPage() {
    this.checkboxService.updateCheckboxStateForCurrentPage(
      this.dataSource,
      this.selection,
      this.globalSelectedIds,
      this.selectedId
    );
  }

  private setIconHeader() {
    if (this.globalSelectedIds.size > 0) {
      this.tableSelectionEvent.emit(ActionIcons.selected);
    } else {
      this.tableSelectionEvent.emit(ActionIcons.fetchResult);
    }
  }
}
