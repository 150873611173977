import { Injectable } from '@angular/core';
import { TableColumnsProp } from '../../models/tables.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  selectionTableNames,
  GridColumnsStorageNames,
  pageType,
  TableProps,
} from '../../utility/constants';
import {
  AccountColumnList,
  AgentColumnList,
  AgentFacilityColumnList,
  ManifestBagsCustomizeColumns,
  bagPieceGridDisplayColumns,
  MawbColumnsList,
  MAWBFlightCustomizeColumns,
  MAWBManifestCustomizeColumns,
  shipmentCommodityColumnList,
  SubAccountColumnList,
  shipmentPieceColumnList,
  ShipmentGatewayColumnList,
  ShipmentGatewayPieceColumnList,
  statusCodeColumnList,
} from './tables.config';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  /* Customize menu selection/deselection in the table */
  public handleTableCustomizeCheckboxSelection(
    index: number,
    isSelected: boolean,
    customizeMenuColumns: TableColumnsProp[],
    tableDisplayedColumns: string[],
    orderedColumns: string[],
    isCustomizeAsLast: boolean
  ) {
    if (!isSelected) {
      customizeMenuColumns[index].isSelected = true;
      tableDisplayedColumns.splice(0, 1); // to remove 'select' from array
      tableDisplayedColumns.splice(tableDisplayedColumns.length - 1, 1); // to remove 'action' from array
      tableDisplayedColumns.splice(index, 0, customizeMenuColumns[index].key);
      tableDisplayedColumns.unshift('select'); // to add 'select' as 1st position in array
      isCustomizeAsLast
        ? tableDisplayedColumns.push('customize')
        : tableDisplayedColumns.push('action'); // to add 'action' or 'customize' as last position in array
      tableDisplayedColumns.sort(
        (a, b) => orderedColumns.indexOf(a) - orderedColumns.indexOf(b)
      );
    } else {
      customizeMenuColumns[index].isSelected = false;
    }
  }

  /* Drag & drop columns in the table */
  public dragAndDropGridColumns(
    event: CdkDragDrop<string[]>,
    displayedColumns: string[],
    customiseTableColumns: TableColumnsProp[],
    storageName: string,
    isCustomizeAsLast: boolean
  ) {
    moveItemInArray(
      displayedColumns,
      event.previousIndex + 1,
      event.currentIndex + 1
    ); // in built method to reorder columns
    const unselectedColumns = customiseTableColumns.filter(
      column => !displayedColumns.includes(column.key)
    ); // filter out unselected columns
    customiseTableColumns = customiseTableColumns.filter(column =>
      displayedColumns.includes(column.key)
    );
    customiseTableColumns.sort(
      (a, b) =>
        displayedColumns.indexOf(a.key) - displayedColumns.indexOf(b.key)
    );
    customiseTableColumns.push(...unselectedColumns); // after sort adding unselected column at last
    const columnsOrderToSave = customiseTableColumns.map(obj => obj.key);
    columnsOrderToSave.unshift('select'); // to add 'select' as 1st position in array
    isCustomizeAsLast
      ? columnsOrderToSave.push('customize')
      : columnsOrderToSave.push('action'); // to add 'action' as last position in array
    localStorage.setItem(storageName, JSON.stringify(columnsOrderToSave));
    return customiseTableColumns;
  }

  /* get storage name for the table */
  public getStorageName(isFrom: string) {
    const storageNameObj = {
      [pageType.mawb]: GridColumnsStorageNames.mawb,
      [selectionTableNames.MawbManifest]: GridColumnsStorageNames.mawbManifest,
      [selectionTableNames.MawbFlight]: GridColumnsStorageNames.mawbFlight,
      [pageType.agent]: GridColumnsStorageNames.agent,
      [pageType.agentInformation]: GridColumnsStorageNames.agentFacility,
      [pageType.hawb]: GridColumnsStorageNames.hawb,
      [pageType.manifest]: GridColumnsStorageNames.manifest,
      [pageType.bag]: GridColumnsStorageNames.bag,
      [pageType.shipmentCommodity]: GridColumnsStorageNames.shipmentCommodity,
      [pageType.account]: GridColumnsStorageNames.account,
      [pageType.subAccount]: GridColumnsStorageNames.subAccount,
      [pageType.shipmentGateway]: GridColumnsStorageNames.shipmentGateway,
      [pageType.shipmentGatewayPiece]: GridColumnsStorageNames.shipmentGateway,
      [pageType.statusCode]: GridColumnsStorageNames.statusCode,
    };
    return storageNameObj[isFrom] || '';
  }

  /* get default display columns for the table */
  public getDefaultDisplayColumns(isFrom: string) {
    let defaultDisplayColumns: string[] = [];
    const mapPageToColumn = {
      [pageType.agent]: AgentColumnList,
      [pageType.agentInformation]: AgentFacilityColumnList,
      [pageType.account]: AccountColumnList,
      [pageType.subAccount]: SubAccountColumnList,
      [pageType.shipmentGateway]: ShipmentGatewayColumnList,
      [pageType.shipmentGatewayPiece]: ShipmentGatewayPieceColumnList,
      [pageType.statusCode]: statusCodeColumnList,
    };
    if (isFrom === pageType.mawb) {
      defaultDisplayColumns = MawbColumnsList;
    } else if (isFrom === selectionTableNames.MawbManifest) {
      defaultDisplayColumns = MAWBManifestCustomizeColumns;
    } else if (isFrom === selectionTableNames.MawbFlight) {
      defaultDisplayColumns = MAWBFlightCustomizeColumns;
    } else if (isFrom === pageType.shipmentCommodity) {
      defaultDisplayColumns = shipmentCommodityColumnList;
    } else if (isFrom === pageType.shipmentPiece) {
      defaultDisplayColumns = shipmentPieceColumnList;
    } else if (isFrom === selectionTableNames.ManifestBag) {
      defaultDisplayColumns = ManifestBagsCustomizeColumns;
    } else if (isFrom === pageType.bagPiece) {
      defaultDisplayColumns = bagPieceGridDisplayColumns;
    } else {
      defaultDisplayColumns = mapPageToColumn[isFrom];
    }

    return defaultDisplayColumns;
  }

  /* get from local storage */
  public getFromLocalStorage(isFrom: string) {
    return JSON.parse(
      localStorage.getItem(this.getStorageName(isFrom)) ??
        JSON.stringify(this.getDefaultDisplayColumns(isFrom))
    );
  }

  /* set to local storage */
  public setToLocalStorage(storageName: string, displayColumns: any) {
    localStorage.setItem(storageName, displayColumns);
  }

  /* to check for input row in table */
  public rowContainsInput(row: any): boolean {
    return (
      Object.prototype.hasOwnProperty.call(row, TableProps.inputRow) &&
      row.isInputRow
    );
  }
}
